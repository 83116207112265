import axios from "axios";
import React from "react";

class CallTheBossPage extends React.Component {

    state = {
        page: '/calltheboss.html'
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log("componentDidMount");
        let self = this;
        this.cancelTokenSource = axios.CancelToken.source();
        let config = {};
        config.cancelToken = this.cancelTokenSource.token;
        axios.get(process.env.REACT_APP_BACKEND_URL+"/zoom", config)
        .then(function(response) {
            console.log("Axios returned: " + response.data);
            const joinUrl = process.env.REACT_APP_FRONTEND_URL+"/meeting.html?" + response.data;
            self.setState({page: joinUrl});
        })
        .catch(function(error) {
            console.log(error);
        })
    }

    componentWillUnmount() {
        console.log("componentWillUnmount")
		this.cancelTokenSource.cancel("");
    }

    render() {
        //return <div>Calling the boss...</div>;
        console.log("render: " + this.state.page);
        return <iframe src={this.state.page} width="100%" style={{height: '94vh'}}></iframe>;
    }
}

export default CallTheBossPage;