import React from "react";
import { Outlet } from "react-router-dom";

class BackToHome extends React.Component {
    render() {
    return (
      <div>
        <div className="d-flex align-items-center justify-content-center" style={{ height: '50px'}}><a className="home-btn" href="/"><span className="home-text">Home</span></a></div>
        <Outlet/>
      </div>
    );
    }
}

export default BackToHome;
